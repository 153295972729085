<template>

    <div class="p-grid">
        <div class="p-col-12 p-md-12">
            <div class="card p-fluid">
                
                <h4>User Profile</h4>
                <h6>
                    <i style="font-size: 2rem;" class="pi pi-check-circle p-mr-2 p-p-2 white-bgcolor blue-color "></i>
                    (a work in progress)
                    </h6>
                
            </div>
        </div>
    </div>
</template>


<script>
</script>

<style scoped>

</style>
